import { WebRoutes } from './routes';
import WebProvider from "./context/webcontext"

function App() {
  return (
    <WebProvider>
      <WebRoutes />
    </WebProvider>
  );
}

export default App;
