import { useContext } from "react";
import { Tecnologias } from "../../styles/techlist";
import { WebContext } from "../../context/webcontext";

export interface iTechList {
    id: string;
    title: string;
    status: string;
}

interface iTechData {
    techData: iTechList[];
}

export function TechList ({techData}: iTechData){
    
    const { delTech } = useContext(WebContext); 
    if(!techData.length){
        return (
            <Tecnologias>
                <ul>
                    Não há tecnologias cadastradas.
                </ul>
            </Tecnologias>
        );
    }
    else{
        return (
        <Tecnologias><ul>
            {techData.map((tech) => {
                return (<li key={tech.id}>
                    <p>{tech.title}</p>
                    <div>
                        <small>{tech.status}</small>
                        <span id={tech.id} className="material-symbols-outlined" onClick={() => delTech(tech.id)}>delete</span>
                    </div>
                </li>)
            })}
        </ul></Tecnologias>
        );
    }
}