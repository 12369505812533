import { Modal, ModalContainer } from "../../styles/modal";
import { TechForm } from "../formtech";

export function ModalWindow (){
    return (
        <Modal>
            <ModalContainer >
                <TechForm />
            </ModalContainer>
        </Modal>
    );
}