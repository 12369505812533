import {RegisterContainer, ExitLink} from "../../styles/container";
import logo from "../../assets/logo.png"
import { FormRegister } from "../../components/formregister";

export function Register(){
     
    return (
        <RegisterContainer>
            <nav>
                <img src={logo} alt="Kenzie Hub Logo"/>
                <ExitLink to={"/"}>Voltar</ExitLink>
            </nav>
            <FormRegister />
        </RegisterContainer>
    );
}