import {HomeContainer, ExitLink} from "../../styles/container";
import { ModalLink } from "../../styles/techlist";
import logo from "../../assets/logo.png"
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { WebContext } from "../../context/webcontext";
import { TechList } from "../../components/techlist";
import { ModalWindow } from "../../components/modal";

export function Home(){
    const navigate = useNavigate();
    const { loading, profile, modal, setModal, logOff } = useContext(WebContext); 
    const userToken = localStorage.getItem("KenzieHub:Token");

    useEffect (() => {
        if(!userToken) { navigate('/');}
    },[navigate, userToken]);

    if(loading) return <h3 className="loading">Loading</h3>;
    else {
        return (
            <>
            {modal? <ModalWindow />:null}
            <HomeContainer>
                <nav>
                    <img src={logo} alt="Kenzie Hub Logo"/>
                    <ExitLink to={"/"} onClick={() => logOff()}>Sair</ExitLink>
                </nav>
                <header>
                    <div>
                        <h3>Olá, {profile?.name}</h3>
                        <p>{profile?.course_module}</p>
                    </div>
                </header>
                <main>
                    <div className="ulHeader">
                        <h3>Tecnologias</h3>
                        <ModalLink onClick={() => setModal(true)}>+</ModalLink>
                    </div>
                    {profile? <TechList techData={profile?.techs} /> : ''}
                    
                </main>
            </HomeContainer>
            </>
        );
    }
}