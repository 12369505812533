import { Forms, RegisterLink } from "../../styles/forms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext } from "react";
import { WebContext } from "../../context/webcontext";

export interface iUserLogin {
    email: string;
    password: string;
}

export function FormLogin(){
    const {onLogin} =  useContext(WebContext);
    
    const yupSchema = yup.object().shape({
        email: yup.string().email("Email inválido").required("Preencha o campo de email."),
        password: yup.string().required("Preencha o campo com uma senha.")
    });
    const { register, handleSubmit, formState: { errors } } = useForm<iUserLogin>({
        resolver: yupResolver(yupSchema),
    });

    return (
        <Forms onSubmit={handleSubmit(onLogin)}>
            <h2>Login</h2>
            <label htmlFor="logEmail">
                <p>Email</p>
                <input type="email" placeholder="E-mail de acesso" {...register("email")}/>
                <span>{errors.email?.message}</span>
            </label>
            <label htmlFor="logPass">
                <p>Senha</p>
                <input type="password" placeholder="Senha de acesso" {...register("password")}/>
                <span>{errors.password?.message}</span>
            </label>
            <button type="submit">Entrar</button>
            <strong>Ainda não possui uma conta?</strong>
            <RegisterLink to={'/register'}>Cadastre-se</RegisterLink>
        </Forms>
    );
}