import {LoginContainer} from "../../styles/container";
import logo from "../../assets/logo.png"
import { FormLogin } from "../../components/formlogin";

export function Login(){
    const token = localStorage.getItem("KenzieHub:Token");
        if(token)window.location.replace('./home');
    return (
    <LoginContainer>
        <img src={logo} alt="Kenzie Hub Logo"/>
        <FormLogin />
    </LoginContainer>
    );
}