import { createContext, useEffect, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { apiAuth } from "../services/request";
import { iUserLogin } from "../components/formlogin"
import { iUserRegister } from "../components/formregister"
import { iTechForm } from "../components/formtech"
import { iTechList } from "../components/techlist"

interface iWebProviderChildren {
    children: ReactNode
}

interface iWebContext {
    logOff: () => void;
    onLogin: (info: iUserLogin) => void;
    onRegister: (info: iUserRegister) => void;
    delTech: (id: string) => void;
    addTech: (info :iTechForm) => void;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    modal: boolean;
    profile: iUserProfile | null;
}

export interface iUserProfile {
    name: string;
    course_module: string;
    techs: iTechList[];
}

export const WebContext = createContext<iWebContext>({} as iWebContext);

function WebProvider ({children}: iWebProviderChildren){
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);

    useEffect (() => {
        async function getProfile(){
            const token = localStorage.getItem("KenzieHub:Token");
            if(token){
                apiAuth.defaults.headers.authorization = `Bearer ${token}`;
                const dataProfile = await apiAuth
                .get('/profile')
                .then((res) => res.data)
                .catch((err => {
                    toast.error(`Ops, algo deu errado!  ${err.response.data.message}`,{
                        style: {
                            background: "var(--Grey-3)",
                            color: "var(--Grey-1)",
                            fontWeight: "700",
                          },
                      });
                    localStorage.clear();
                    return null
                }))
                setProfile(dataProfile);
                setLoading(false);
            }
        }
 
        getProfile();
    }, []);

    async function onLogin(info: iUserLogin){
        const logUser = await apiAuth
        .post('/sessions', info)
        .then((res) => res.data)
        .catch((err => {
            toast.error(`Ops, algo deu errado!  ${err.response.data.message}`,{
                style: {
                    background: "var(--Grey-3)",
                    color: "var(--Grey-1)",
                    fontWeight: "700",
                  },
              });
            return false
        }))
        if(logUser){
            localStorage.setItem("KenzieHub:Token", logUser.token);
            setTimeout(() => window.location.replace('./home'), 500);
        }
    }

    async function onRegister(info: iUserRegister){
        const logUser = await apiAuth
        .post('/users', info)
        .then((res) => res.data)
        .catch((err => {
            toast.error(`Ops, algo deu errado!  ${err.response.data.message}`, {
                style: {
                  background: "var(--Grey-3)",
                  color: "var(--Grey-1)",
                  fontWeight: "700",
                },
              });
            return false
        }))
        if(logUser){
            toast.success("Conta criada com sucesso!", {
                style: {
                  background: "var(--Grey-3)",
                  color: "var(--Grey-1)",
                  fontWeight: "700",
                },
              });
            ;
            setTimeout(() => navigate('/'), 500);
        }
    }

    async function addTech(info :iTechForm){
        const token = localStorage.getItem("KenzieHub:Token");
            if(token){
                apiAuth.defaults.headers.authorization = `Bearer ${token}`;
                await apiAuth
                .post('/users/techs', info)
                .catch((err => {
                    toast.error(`Ops, algo deu errado!  ${err.response.data.message}`,{
                        style: {
                            background: "var(--Grey-3)",
                            color: "var(--Grey-1)",
                            fontWeight: "700",
                          },
                      });
                }))
                setModal(false);
                
                apiAuth.defaults.headers.authorization = `Bearer ${token}`;
                const dataProfile = await apiAuth
                .get('/profile')
                .then((res) => res.data)
                setProfile(dataProfile);
            }
    }

    async function delTech(info :string){
        console.log(info);
        const token = localStorage.getItem("KenzieHub:Token");
            if(token){
                apiAuth.defaults.headers.authorization = `Bearer ${token}`;
                await apiAuth
                .delete(`/users/techs/${info}`)
                .catch((err => {
                    toast.error(`Ops, algo deu errado!  ${err.response.data.message}`,{
                        style: {
                            background: "var(--Grey-3)",
                            color: "var(--Grey-1)",
                            fontWeight: "700",
                          },
                      });
                }))
                apiAuth.defaults.headers.authorization = `Bearer ${token}`;
                const dataProfile = await apiAuth
                .get('/profile')
                .then((res) => res.data)
                setProfile(dataProfile);
            }
    }

    function logOff(){
        localStorage.clear();
    }

    return (
        <WebContext.Provider value={{loading, profile, modal, setModal, addTech, delTech, onLogin, onRegister, logOff}}>
            {children}
        </WebContext.Provider>
    );

}

export default WebProvider

