import { Forms } from "../../styles/forms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext } from "react";
import { WebContext } from "../../context/webcontext";

export interface iUserRegister {
    name: string;
    email: string;
    password: string;
    checkpass: string;
    bio: string;
    contact: string;
    course_module: string;
}

export function FormRegister(){
    const {onRegister} =  useContext(WebContext);

    const yupSchema = yup.object().shape({
        name: yup.string().required("Preencha o campo com seu nome."),
        email: yup.string().required("Preencha o campo com um email válido.").email("Email inválido"),
        password: yup.string().required("Preencha o campo com uma senha.").min(6, "Senha com um mínimo de 6 caracteres"),
        checkpass: yup.string().oneOf([yup.ref('password')], "Senha diferente."),
        bio: yup.string().required("Preencha o campo sobre você."),
        contact: yup.string().required("Preencha o campo de contato.")
    });
    const { register, handleSubmit, formState: { errors } } = useForm<iUserRegister>({
        resolver: yupResolver(yupSchema),
    });

    return (
        <Forms onSubmit={handleSubmit(onRegister)}>
            <h2>Crie sua conta</h2>
            <strong>Rápido e grátis, vamos nessa.</strong>
            <label htmlFor="userNome">
                <p>Nome</p>
                <input type="text" placeholder="Digite aqui seu nome" {...register("name")}/>
                <span>{errors.name?.message}</span>
            </label>
            <label htmlFor="userEmail">
                <p>Email</p>
                <input type="email" placeholder="Digite aqui seu email" {...register("email")}/>
                <span>{errors.email?.message}</span>
            </label>
            <label htmlFor="userPass">
                <p>Senha</p>
                <input type="password" placeholder="Digite aqui sua senha" {...register("password")}/>
                <span>{errors.password?.message}</span>
            </label>
            <label htmlFor="checkPass">
                <p>Confirmar Senha</p>
                <input type="password" placeholder="Digite novamente sua senha" {...register("checkpass")}/>
                <span>{errors.checkpass?.message}</span>
            </label>
            <label htmlFor="userBio">
                <p>Bio</p>
                <input type="text" placeholder="Fale sobre você" {...register("bio")}/>
                <span>{errors.bio?.message}</span>
            </label>
            <label htmlFor="userContato">
                <p>Contato</p>
                <input type="text" placeholder="Opção de contato" {...register("contact")}/>
                <span>{errors.contact?.message}</span>
            </label>
            <label htmlFor="userModulo">
                <p>Selecionar módulo</p>
                <select  {...register("course_module")}>
                    <option value="Primeiro módulo (Introdução ao Frontend)">Módulo 1</option>
                    <option value="Segundo módulo (Frontend Avançado)">Módulo 2</option>
                    <option value="Terceiro módulo (Introdução ao Backend)">Módulo 3</option>
                    <option value="Quarto módulo (Backend Avançado)">Módulo 4</option>
                </select>
                <span>{errors.course_module?.message}</span>
            </label>
            <button type="submit">Cadastrar</button>
        </Forms>
    );
}